@font-face {
  font-family: 'FiraSansRedesign';
  src: url('./FiraSans-Medium.ttf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'FiraSansRedesign';
  src: url('./FiraSans-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'FiraSansRedesign';
  src: url('./FiraSans-SemiBold.ttf');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'FiraSans';
  src: url('./FiraSans-Medium.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'FiraSans';
  src: url('./FiraSans-SemiBold.ttf');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'FiraSansCondensed';
  src: url('./FiraSansCondensed-Book.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'FiraSansCondensed';
  src: url('./FiraSansCondensed-SemiBold.ttf');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Signika';
  src: url('./Signika-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Signika';
  src: url('./Signika-SemiBold.ttf');
  font-weight: bold;
  font-style: normal;
}
